import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import {
	Container,
	Grid,
	Typography,
	Button,
	useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Hero } from '../../components/General/Hero';
import { WaveUpSVG } from '../../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../../components/SEO';
import { Resources } from '../../components/General/Resources';
import { ProductFeatureBody } from '../../components/ProductFeature/ProductFeatureBody';
import { HeroVideo } from '../../components/FinancialServices/Product/Hero/HeroVideo';

const Form = loadable(() => import('../../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	heroVideoCont: {
		marginBottom: '2rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '4rem',
			marginBottom: '2rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '3rem',
		},
	},
	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '14rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
	faqTitle: {
		color: theme.workwaveBlue,
	},
}));

const FinancialServices = ({ data, location }) => {
	const product = data.fs.edges[0].node.product;
	const classes = useStyles();

	const lg = useMediaQuery('(max-width: 1280px)');
	const theme = useTheme();

	const {
		productPageHero,
		metaDescription,
		metaTitle,
		contactForm,
		formImages,
		formBgImage,
		pardotUrl,
		resourceTitle,
		resources,
		_rawResourceBody,
		faqCTALink,
		faqCTAText,
		faqTitle,
		videoFallbackImage,
		wistiaLink,
		cta,
	} = product;

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={productPageHero} fs video />
			<Container className={classes.heroVideoCont}>
				<HeroVideo
					videoFallbackImage={videoFallbackImage}
					wistiaLink={wistiaLink}
				/>
			</Container>
			<ProductFeatureBody body={product} heroVideo cta={cta} />
			<Container>
				<Grid
					style={{ padding: lg ? '2rem 0 ' : '4rem 0 0 0' }}
					container
					spacing={4}
					justifyContent='center'
					alignItems='center'
					direction='row'>
					<Grid item xs={12} container justifyContent='center'>
						<Typography variant='h2' className={classes.faqTitle}>
							{faqTitle}
						</Typography>
					</Grid>
					<Grid item container justifyContent='center'>
						<a href={faqCTALink} target='_blank'>
							<Button
								variant='contained'
								size='large'
								className={classes.button}
								style={{
									background: theme.workwaveBlue,
									color: 'white',
								}}>
								{faqCTAText}
							</Button>
						</a>
					</Grid>
				</Grid>
			</Container>
			<div>
				<WaveDownSVG height='213' width='100%' fill='white' />
			</div>

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				<Form
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					modal={false}
					formImages={formImages}
					location={location}
				/>
			</div>
			{/* <WaveUpSVG height='213' width='100%' fill='white' /> */}
			<Container>
				<Resources
					header={resourceTitle}
					subheader={_rawResourceBody}
					resources={resources}
					noWave
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query FSProductPageQuery {
		fs: allSanityFinancialServices {
			edges {
				node {
					product: fsProductPage {
						metaTitle
						metaDescription
						productPageHero {
							ctaText
							backgroundImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							statsImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							_rawContent
						}
						videoFallbackImage {
							asset {
								gatsbyImageData
							}
						}
						wistiaLink
						featureHeader
						featureSection {
							_key
							title
							cardTitle
							cardFaIcon
							cardIconColor {
								hexValue
							}
							bodySubHeader
							bodyHeader
							image {
								featureImage {
									asset {
										gatsbyImageData(placeholder: BLURRED)
									}
								}
							}
							videoVariant
							androidLink
							iosLink
							qbLink
							# svgVariant
							thumbnailVideoUrl
							ctaText
							ctaLink
							_rawBodyCopy
							internalLink
						}
						# fsCallout {
						#   ctaBackground {
						#     asset {
						#       gatsbyImageData(placeholder: BLURRED)
						#     }
						#   }
						#   ctaHeader
						#   ctaSubheader
						#   ctaText
						#   internalLink
						#   ctaLink
						# }
						cta {
							ctaHeader
							ctaSubheader
							ctaLink
							ctaText
							internalLink
							ctaBackground {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
						testimonialTitle
						testimonial {
							title
							header
							testimonialLogo {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							company
							testimonialText
							nameAndTitle
							videoVariant
							image {
								asset {
									gatsbyImageData(
										fit: FILLMAX
										height: 450
										width: 775
										placeholder: BLURRED
									)
								}
							}
						}
						testimonialBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						customerLogosHeader
						customerLogos {
							logo {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
						contactForm {
							header
							privacyPolicy
						}
						formImages {
							image {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							link
						}
						formBgImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						pardotUrl
						resourceTitle
						resources {
							title
							image {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							blurb
							ctaText
							ctaLink
						}
						# _rawOverview
						_rawResourceBody
						faqCTALink
						faqCTAText
						faqTitle
					}
				}
			}
		}
	}
`;

export default FinancialServices;
